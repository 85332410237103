import { render, staticRenderFns } from "./GroupStructuresComponent.vue?vue&type=template&id=7bdf452b&scoped=true"
import script from "./GroupStructuresComponent.vue?vue&type=script&lang=js"
export * from "./GroupStructuresComponent.vue?vue&type=script&lang=js"
import style0 from "./GroupStructuresComponent.vue?vue&type=style&index=0&id=7bdf452b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bdf452b",
  null
  
)

export default component.exports